<template>
  <div>
    <el-row :gutter="20" class="search-box">
      <el-col :span="4">
        <el-select
          v-model="query.operationId"
          placeholder="请选择代理商"
          :maxLength="50"
          @change="getList(1)"
          clearable
        >
          <el-option
            v-for="(item, i) in agentList"
            :value="item.id"
            :label="item.operationName"
            :key="i"
          ></el-option>
        </el-select>
      </el-col>


      <el-col :span="4">
        <el-button type="primary" @click="getList(1)">查询</el-button>
      </el-col> 
    </el-row>
    <div>
      <el-button class="but" type="primary" @click="showAddUser" v-auth="this.per.AGENT_EMPLOYEE_ADD"
        >添加账户</el-button
      >
    </div>
    <el-table
      border
      :data="dataSource"
      class="page-top-space"
      row-class-name="table-row"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column label="昵称" prop="nickName"></el-table-column>
      <el-table-column label="用户名" prop="userName"></el-table-column>
      <el-table-column
        label="所属代理商"
        prop="operationName"
      ></el-table-column>
      <el-table-column label="手机号码" prop="phone"></el-table-column>
      <el-table-column label="邮箱" prop="email"></el-table-column>
      <el-table-column label="状态" prop="status">
        <template #default="scope">
          <eb-badge
            :list="this.const.STATUS"
            :target="scope.row.status"
          ></eb-badge>
        </template>
      </el-table-column>

      <el-table-column label="操作">
        <template #default="scope">
          <span class="option option-primary" @click="showEditUser(scope.row)" v-auth="this.per.AGENT_EMPLOYEE_UPDATA">
            修改
          </span>
          <span class="option option-danger" @click="doDel(scope.row)"  v-auth="this.per.AGENT_EMPLOYEE_DEL"
            >删除</span
          >

          <span
          v-auth="this.per.AGENT_EMPLOYEE_UPDATA"
            class="option option-primary"
            @click="showUpdateRole(scope.row)"
          >
            设置角色
          </span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout=" total,prev, pager, next"
      background
      :total="total"
      :page-size="pages.pageSize"
      :current-page="pages.pageIndex"
      @current-change="getList"
    ></el-pagination>

    <EditModal
      :agentList="agentList"
      :modalData="modalData"
      :modalType="modalType"
      :ref="MODAL_KEY.EDIT_MODAL"
      @submit="submitUser"
    ></EditModal>

    <EditRoleModal
      :roleList="roleList"
      :modalData="modalData"
      @submit="updateRole"
      :ref="MODAL_KEY.ROLE_MODAL"
    ></EditRoleModal>
  </div>
</template>

<script>
import { fetchUserList, addUser, updateUser, delUser ,updateUserRole} from "@/api/user";
import EditModal from "./component/EditModal";
import EditRoleModal from "./component/EditRoleModal";
import { areas } from "@/common/area.js";
import { mapState } from "vuex";

// 10本公司管理员，11公司员工，20代理商管理员，21代理商员工 30分销商管理员，31分销商员工
const ROLE_TYPE = {
  AGENT_ADMIN: 20,
  AGENT_EMPLOYEE: 21
}

const ROLE_LIST = [
  {value:ROLE_TYPE.AGENT_ADMIN,name: '代理商管理员'},
  {value:ROLE_TYPE.AGENT_EMPLOYEE,name: '代理商员工'},
]

export default {
  components: { EditModal,EditRoleModal },
  computed: mapState({
    agentList: (state) => {
      return state.agent.agentList || [];
    },
  }),
  data() {
    return {
      ROLE_LIST:ROLE_LIST,
      dataSource: [],
      roleList: [],
      query: {
        operationId:'',
      },
      pages: {
        pageIndex: 1,
        pageSize: 10,
      },
      modalData: {},
      modalType: "add",
      MODAL_KEY: {
        ROLE_MODAL:"ROLE_MODAL",
        EDIT_MODAL: "EDIT_MODAL",
      },
      total: 0,
    };
  },
  methods: {
    // 删除用户
    doDel(e) {
      this.$confirm("是否要删除用户", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then((res) => {
        delUser({ id: e.id }).then((res) => {
          this.getList();
          this.$message.success("删除成功");
        });
      });
    },
    showAddUser() {
      this.modalData = {};
      this.modalType = "add";
      this.$refs[this.MODAL_KEY.EDIT_MODAL].showModal();
    },
    showEditUser(data) {
      this.modalData = this.deepClone(data);
      this.modalType = "edit";
      this.$refs[this.MODAL_KEY.EDIT_MODAL].showModal();
    },

    submitUser(data) {
      let request = updateUser;
      let msg = "编辑用户成功";
      if (this.validatenull(data.id)) {
        request = addUser;
        msg = "添加用户成功";
        data.roleType = this.const.ROLE_TYPE_CODE.AGENT_MANAGE;
        data.stationId = 0;
      }

      request(data).then((res) => {
        this.$message.success(msg);
        this.$refs[this.MODAL_KEY.EDIT_MODAL].closeModal();
        this.getList();
      });
    },

    getList(current) {
      if (!this.validatenull(current)) {
        this.pages.pageIndex = current;
      }
      fetchUserList({...this.query, ...this.pages }).then(
        (res) => {
          this.dataSource = res.data.data.records || [];
          this.total = res.data.data.total;
          this.dataSource.map((item) => {
            item.operationName = this.agentList.map[item.operationId];
          });
        }
      );
    },
  },

  mounted() {
    this.$store.dispatch("fetchAgentListAll").then((res) => {
      this.getList();
    });
  },
};
</script>
