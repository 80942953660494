<template>
  <el-dialog
    title="修改角色"
    :close-on-click-modal="isClickModalClose"
    v-model="isShow"
    :width="defaultWidth"
    @close="closeModal"
  >
    <el-form
      :ref="formName"
      :model="modalData"
      :rules="ruleValidate"
      label-width="110px"
    >
      <el-form-item label="昵称：">
        {{ modalData.nickName }}
      </el-form-item>

      <el-form-item label="角色：" prop="roleId">
        <el-select placeholder="请选择角色" v-model="modalData.roleId">
          <el-option
            v-for="item in roleList"
            :label="item.roleName"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button @click="closeModal"> 取消 </el-button>
      <el-button @click="submit" type="primary"> 确定 </el-button>
    </template>
  </el-dialog>
</template>

<script>
import modalMixin from "@/mixins/modalMixin";
export default {
  mixins: [modalMixin],
  emits: ["submit"],
  props: ["modalData", "modalType", "roleList"],
  data() {
    return {
      formName: "form",
      ruleValidate: {
        roleId: [
          {
            required: true,
            message: "请选择角色",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    submit() {
      this.validateForm().then((res) => {
        let obj = {
          adminId: this.modalData.id,
          roles: [this.modalData.roleId],
        };
        this.$emit("submit", obj);
      });
    },
  },
};
</script>
